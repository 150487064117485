/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */
const fetchWithRetry = async (url, options) => {
  const MAX_RETRIES = 4;
  let error = Error("something went wrong");
  for (let i = 0; i < MAX_RETRIES; i++) {
    try {
      return await fetch(url, options);
    } catch (err) {
      error = err;
    }
  }
  console.error("Fetch failed after max retries", { url, options });
  throw error;
};

export default async function getToken(tokenEndpoint, userId, role, roomId) {
  try {
    while (!localStorage.getItem("ospreys-jwt")) {
      await new Promise(r => setTimeout(r, 500));
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("ospreys-jwt")}`
    );
    var urlencoded = new URLSearchParams();
    urlencoded.append("roomId", roomId);
    const response = await fetchWithRetry(`${tokenEndpoint}`, {
      method: "POST",
      body: urlencoded,
      headers: myHeaders,
    });

    if (!response.ok) {
      let error = new Error("Request failed!");
      error.response = response;
      throw error;
    }

    const responseJson = await response.json();
    const { token } = responseJson.data;
    if (token === null) {
      throw Error(responseJson.message);
    }
    return token;
  } catch (err) {
    console.error(err);
    throw err;
  }
}
